import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Trad, Trads, TradsResponse } from './Translations.type';

const URL_API = 'https://www.brandpredictor.ch/';

const TextInput = ({
  trad,
  onChange,
  lang,
}: {
  trad: Trad;
  onChange: (trad: Trad) => void;
  lang: 'fr' | 'en' | 'de';
}) => {
  return (
    <TextField
      sx={{
        width: '100%',
        textarea: {
          resize: 'both',
        },
      }}
      defaultValue={trad[lang]}
      onBlur={(e) => {
        if (e.target.value === trad[lang]) {
          return;
        }
        onChange({
          ...trad,
          [lang]: e.target.value,
        });
      }}
      multiline
    />
  );
};

const Line = ({
  trad,
  isHeader,
  onChangeTrad,
}: {
  trad: Trad;
  isHeader?: boolean;
  onChangeTrad: (trad: Trad) => void;
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: isHeader ? '1px solid #ccc' : 'none',
        paddingBottom: '1rem',
        paddingLeft: '1rem',
        marginBottom: isHeader ? '2rem' : '.5rem',
        overflowX: 'auto',
        gap: 2,
      }}
      key={trad.key}
    >
      <Box
        sx={{
          width: '20%',
          minWidth: '100px',
          display: 'inline-block',
        }}
      >
        {trad.key.split('.').map((key, index) => {
          if (index === 0) {
            return (
              <span
                style={{
                  display: 'inline-block',
                }}
                key={'key_' + key}
              >
                {key}
              </span>
            );
          }
          return (
            <span
              style={{
                display: 'inline-block',
              }}
              key={'key_k' + key}
            >
              .{key}
            </span>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '24%',
          minWidth: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {isHeader ? (
          trad.en
        ) : (
          <TextInput trad={trad} onChange={onChangeTrad} lang="en" />
        )}
      </Box>

      <Box
        sx={{
          width: '24%',
          minWidth: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {isHeader ? (
          trad.de
        ) : (
          <TextInput trad={trad} onChange={onChangeTrad} lang="de" />
        )}
      </Box>
      <Box
        sx={{
          width: '24%',
          minWidth: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {isHeader ? (
          trad.fr
        ) : (
          <TextInput trad={trad} onChange={onChangeTrad} lang="fr" />
        )}
      </Box>
    </Box>
  );
};

const Translations = () => {
  const [trads, setTrads] = React.useState<Trads>([]);
  const [changedTrads, setChangedTrads] = React.useState<Trads>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  // Success text or error text
  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState<'success' | 'error'>(
    'success'
  );

  const getTrads = async () => {
    setIsLoading(true);
    const response = await fetch(`${URL_API}api.php/records/translations`);
    const data: TradsResponse = await response.json();
    //Sort by key alphabetically
    const payload = data.records.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }
      return 0;
    });
    setTrads(payload);
    setIsLoading(false);
  };

  React.useEffect(() => {
    getTrads();
  }, []);

  const onChangeTrad = (trad: Trad) => {
    const index = changedTrads.findIndex((t) => t.key === trad.key);
    if (index !== -1) {
      const newChangedTrads = [...changedTrads];
      newChangedTrads[index] = trad;
      setChangedTrads(newChangedTrads);
      return;
    } else {
      setChangedTrads([...changedTrads, trad]);
    }
    setTrads(
      trads.map((t) => {
        if (t.key === trad.key) {
          return trad;
        }
        return t;
      })
    );
  };

  const saveTrads = async () => {
    if (changedTrads.length === 0) {
      return;
    }

    const ids = changedTrads.map((t) => t.id).join(',');

    await fetch(`${URL_API}api.php/records/translations/${ids}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changedTrads),
    })
      .then((res) => {
        setMessageType('success');
        setMessage('Translations saved successfully.');
        setChangedTrads([]);

        setTimeout(() => {
          setMessage('');
        }, 3000);

        return res;
      })
      .catch((e) => {
        setMessageType('error');
        setMessage('There was an error while saving the translations.');

        setTimeout(() => {
          setMessage('');
        }, 3000);

        return e;
      });
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            minHeight: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Loading...
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            maxWidth: '1400px',
            margin: '0 auto',
          }}
        >
          <Line
            trad={{
              key: 'key',
              en: 'en',
              fr: 'fr',
              de: 'de',
              id: 'header_id',
            }}
            isHeader
            onChangeTrad={() => {}}
          />
          {trads.map((trad) => {
            return (
              <Line trad={trad} key={trad.key} onChangeTrad={onChangeTrad} />
            );
          })}
        </Box>
      )}
      {changedTrads.length > 0 && (
        <Button
          sx={{
            marginTop: '2rem',
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            zIndex: 100,
            backgroundColor: 'secondary.main',
            color: 'white',
            padding: '.5rem 1rem',
            borderRadius: '4px',

            '&:hover': {
              backgroundColor: 'secondary.dark',
            },
          }}
          onClick={saveTrads}
        >
          Save
        </Button>
      )}
      {message && (
        <Alert
          sx={{
            position: 'fixed',
            bottom: '2rem',
            left: '2rem',
            zIndex: 1000,
          }}
          severity={messageType}
        >
          {message}
        </Alert>
      )}
    </Box>
  );
};

export default Translations;

