import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDownBlack from '../../assets/arrow_down_black.svg';
import { useTranslation } from 'react-i18next';
import { Brand } from '../Rank/Rank.type';

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

interface DiscoverCardProps {
  changeBrand: (brand: Brand) => void;
  brands: Brand[];
}

const DiscoverBrand: React.FC<DiscoverCardProps> = ({
  changeBrand,
  brands,
}) => {
  const [filterBrands, setFilterBrands] = React.useState<Brand[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    // filter alphabetically
    const payload = brands.sort((a, b) => {
      if (a.brand < b.brand) {
        return -1;
      }
      if (a.brand > b.brand) {
        return 1;
      }
      return 0;
    });

    setFilterBrands(payload);
  }, [brands]);

  return (
    <Autocomplete
      options={filterBrands}
      getOptionLabel={(option) => option.brand}
      sx={{
        width: '100%',
        maxWidth: '550px',
        '.MuiAutocomplete-popupIndicator': {
          transition: 'all .2s ease',
          right: '2rem',
          transform: { xs: 'translateY(5px)', sm: 'translateY(2px)' },
        },
      }}
      clearIcon={null}
      onChange={(event, value) => {
        changeBrand(value as Brand);
      }}
      popupIcon={<img src={ArrowDownBlack} alt="arrow down" />}
      renderInput={(params) => (
        <TextField
          {...params}
          aria-label={t('discover.select')}
          placeholder={t('discover.select')}
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: 'white',
              border: 'none',
              borderRadius: '10px',
              outline: 'none',
              padding: {
                xs: '.6rem 1rem',
                md: '.75rem 2rem',
              },

              '.MuiOutlinedInput-notchedOutline,&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: 'none',
                },
            },
            //placeholer
            '& .MuiInputBase-input,& .MuiInputBase-input::placeholder': {
              color: 'black',
              fontWeight: 500,
              fontSize: { xs: '1.25rem', sm: '1.45rem' },
              opacity: '1',
            },
          }}
        />
      )}
    />
  );
};

export default DiscoverBrand;

