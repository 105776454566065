import { Grid } from '@mui/material';
import { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Translations from './Translations/Translations';
import Brands from './Brands/Brands';
import Login from '../Login/Login';
import React from 'react';
import { Alert } from '@mui/material';
const bcrypt = require('bcryptjs');

const URL_API = 'https://www.brandpredictor.ch/';

const Dashboard = () => {
  const [tab, setTab] = useState<'translations' | 'brands'>('translations');
  const [isConnected, setIsConnected] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const connect = async (email: string, password: string) => {
    await fetch(`${URL_API}api.php/records/users?filter=email,eq,${email}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.records.length === 0) {
          setMessage('There is no user with this email');

          setTimeout(() => {
            setMessage('');
          }, 3000);
          return;
        }

        if (data.records[0].password === null) {
          setMessage('You need to set a password first.');
          setTimeout(() => {
            setMessage('');
          }, 3000);
          return;
        }

        const hash = data.records[0].password;

        bcrypt.compare(password, hash, function (err: any, result: any) {
          if (result) {
            setIsConnected(true);
          } else {
            setMessage('Wrong password');
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
          // setIsConnected(true);
        });
      })
      .catch((e) => {
        setIsConnected(false);
      });
  };

  const openBrands = () => {
    setTab('brands');
  };
  const openTrads = () => {
    setTab('translations');
  };

  const logout = () => {
    setIsConnected(false);
  };
  return (
    <>
      {!isConnected ? (
        <Login connect={connect} />
      ) : (
        <>
          {message && (
            <Alert
              sx={{
                position: 'fixed',
                bottom: '2rem',
                left: '2rem',
                zIndex: 1000,
              }}
              severity="error"
            >
              {message}
            </Alert>
          )}
          <Grid
            container
            sx={{
              width: '100%',
              minHeight: '100vh',
              backgroundColor: '#fefefe',
              display: 'flex',
              alignItems: 'flex-start',
              padding: '2rem',
              flexDirection: 'column',
            }}
          >
            <Navbar
              openBrands={openBrands}
              openTrads={openTrads}
              selected={tab}
              logout={logout}
            />
            <Grid item xs={12} sx={{ mt: '6rem', width: '100% ' }}>
              {tab === 'translations' ? <Translations /> : <Brands />}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Dashboard;

