import { KeyPrefix, Namespace } from 'i18next';
import { useTranslation, UseTranslationOptions } from 'react-i18next';
import { enUS, frFR, deDE } from '@mui/material/locale';
import { LocaleLang, Locales } from './locales.type';
import { $Tuple } from 'react-i18next/helpers';

type defaultNS = 'translations';

const LANGS: LocaleLang[] = [
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,
  },
];

export default function useLocales<
  N extends Namespace = defaultNS,
  TKPrefix extends KeyPrefix<N> = undefined
>(
  ns?: string | $Tuple<string> | undefined,
  options?: UseTranslationOptions<TKPrefix>
): Locales<N, TKPrefix> {
  const { i18n, t } = useTranslation(ns, options);
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang =
    LANGS.find((lang) => lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = async (newlang: string) => {
    return i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLang: LANGS,
  };
}

