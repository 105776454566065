import React from 'react';
import { Button } from '@mui/material';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ReactComponent as ArrowBlack } from '../../assets/arrow_black.svg';

interface CustomButtonProps {
  title: string | React.ReactNode;
  noArrow?: boolean;
  iconAfter?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  noHover?: boolean;
  wide?: boolean;
  sx?: any;
  red?: boolean;
  dark?: boolean;
  small?: boolean;
  textFitContent?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  title,
  noArrow = false,
  iconAfter,
  onClick,
  disabled,
  loading,
  type,
  noHover,
  wide,
  sx,
  red,
  textFitContent,
  dark,
  small,
}) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onClick}
      disabled={loading || disabled}
      type={type}
      sx={{
        color: dark ? 'black' : 'white',
        backgroundColor: red ? '#FF0000' : 'transparent',
        padding: wide
          ? '.65rem 2.15rem'
          : small
          ? '.6rem 1.5rem'
          : { xs: '.55rem 1rem', sm: '.75rem 1.75rem' },
        fontSize: small ? '.75rem' : { xs: '.85rem', sm: '1rem' },
        fontFamily: 'Arial',
        borderRadius: '10px',
        border: red ? 'none' : dark ? '1px solid black' : '1px solid #FFF',
        textTransform: 'none',
        fontWeight: 700,
        minWidth: 'auto',
        opacity: disabled ? 0.5 : 1,
        transition: 'all .2s ease',
        width: textFitContent ? 'max-content' : 'auto',

        '& span': {
          marginLeft: '.75rem',
          transition: 'margin-left 0.2s ease-in-out',
        },

        '&:hover': {
          border: red ? 'none' : dark ? '1px solid black' : '1px solid #FFF',
          backgroundColor: red ? '#FF0000' : 'transparent',

          '& span': {
            marginLeft: noHover ? '.75rem' : '1.5rem',
          },
        },

        '& sup': {
          fontSize: '.5em',
          fontWeight: 'light',
          margin: '-0.2rem 0 0 .15rem',
          fontFamily: 'Arial',
        },

        '&.MuiButtonBase-root.Mui-disabled': {
          opacity: 0.5,
          color: 'white',
          border: red ? 'none' : dark ? '1px solid black' : '1px solid #FFF',
        },
        ...sx,
      }}
      endIcon={
        iconAfter
          ? iconAfter
          : !noArrow &&
            (dark ? (
              <ArrowBlack />
            ) : (
              <Arrow
                style={{
                  fill: red ? 'white' : 'black',
                  width: '1.5rem',
                  height: '1rem',
                }}
              />
            ))
      }
    >
      {title}
    </Button>
  );
};

export default CustomButton;

