import React from 'react';
import { read, utils } from 'xlsx';
import { Box, Button } from '@mui/material';

const URL_API = 'https://www.brandpredictor.ch/';

const UploadExcel = ({
  getBrands,
  deleteAllBrands,
}: {
  getBrands: () => void;
  deleteAllBrands: () => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const readUploadFile = (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });
        const worksheet = workbook.Sheets['brands'];
        if (!worksheet) {
          alert('The sheet name must be "brands"');
          setIsLoading(false);
          return;
        }
        const json: any[] = utils.sheet_to_json(worksheet);
        if (!json.length) {
          setIsLoading(false);
          return;
        }
        // Check if data is correct
        const keys = Object.keys(json[0]);
        const requiredKeys = [
          'brand',
          'aggr_year',
          'rank_year',
          'dynamism_diff_year_min1',
          'trust_diff_year_min1',
        ];

        const missingKeys = requiredKeys.filter((key) => !keys.includes(key));
        if (missingKeys.length) {
          alert(
            `The following keys are missing: ${missingKeys.join(
              ', '
            )}. Please check your file.`
          );
          setIsLoading(false);
          return;
        }

        // convert every page with keys
        const convertedSheet = json.map((page) => {
          return {
            brand: page.brand,
            aggr_2023: page.aggr_year,
            rank_2023: page.rank_year,
            dynamism_diff_2023: page.dynamism_diff_year_min1,
            trust_diff_2023: page.trust_diff_year_min1,
            sustainable: page.sustainable || 0,
            trust: page.trust || 0,
            dynamism: page.dynamism || 0,
          };
        });

        const acceptToUpload = window.confirm(
          'Are you sure you want to upload this file and delete all brands already uploaded ?'
        );
        if (!acceptToUpload) {
          setIsLoading(false);
          return;
        }

        addBrands(convertedSheet);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const addBrands = async (brands: any) => {
    await deleteAllBrands();
    await fetch(`${URL_API}api.php/records/brands`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(brands),
    });
    setIsLoading(false);

    getBrands();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-3rem',
        right: '0',
      }}
    >
      <input
        //Type file xls, xlsx
        accept=".xls,.xlsx"
        type="file"
        name="upload"
        id="upload"
        style={{ display: 'none' }}
        onChange={readUploadFile}
      />
      <label htmlFor="upload">
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'green',
            border: 'none',
          }}
          component="span"
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Upload Excel'}
        </Button>
      </label>
    </Box>
  );
};

export default UploadExcel;

