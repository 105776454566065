import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Brand } from './Rank.type';
import RankItem from './RankItem';

interface RankTableProps {
  items: Brand[];
  field: 'dynamism' | 'trust' | 'sustainable';
}

const RankTable: React.FC<RankTableProps> = ({ items, field }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <RankItem
        key={'header'}
        rank={t('rank.rank2023')}
        black
        fullWidth
        borderBottom={true}
        noBtn
        isHeader
        field={field}
      />
      {items.map((item, index) => {
        return (
          <RankItem
            key={item.id}
            brand={item}
            rank={index + 1}
            black
            fullWidth
            borderBottom={index === items.length - 1 ? false : true}
            noBtn
            field={field}
          />
        );
      })}
    </Grid>
  );
};

export default RankTable;

