import { Box, Typography } from '@mui/material';
import React, { SVGProps } from 'react';

interface FormCardProps {
  title: string;
  description: string;
  Icon: React.FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  isSelected: boolean;
  onChange: () => void;
}

const FormCard: React.FC<FormCardProps> = ({
  title,
  description,
  Icon,
  isSelected,
  onChange,
}) => {
  return (
    <Box
      sx={{
        border: isSelected
          ? '1px solid #E60000'
          : '1px solid rgba(0, 0, 0, 0.10)',
        borderRadius: '10px',
        padding: { lg: '1.05rem', xs: '1rem' },
        cursor: 'pointer',
        minWidth: '130px',
        transition: 'all .2s ease',
        backgroundColor: isSelected ? '#E60000' : 'white',

        svg: {
          circle: {
            fill: isSelected ? 'white' : '#E60000',
            transition: 'all .2s ease',
          },
          path: {
            transition: 'all .2s ease',
            fill: isSelected ? '#E60000' : 'white',
          },
        },

        '&:hover': {
          border: '1px solid #E60000',
          backgroundColor: '#E60000',

          '& h6, & p': {
            color: 'white !important',
          },

          svg: {
            circle: {
              fill: 'white',
            },
            path: {
              fill: '#E60000',
            },
          },
        },
      }}
      onClick={onChange}
    >
      <Icon
        style={{
          width: '2rem',
          height: '2rem',
        }}
      />
      <Typography
        variant="h6"
        sx={{
          fontSize: '1rem',
          fontWeight: 500,
          transition: 'all .2s ease',
          color: isSelected ? 'white' : '#E60000',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: { lg: '.7rem', md: '.55rem' },
          fontWeight: 400,
          color: isSelected ? 'white' : 'black',
          transition: 'all .2s ease',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default FormCard;

