import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const URL_API = 'https://www.brandpredictor.ch/';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${URL_API}api.php/records/translations`,
      crossDomain: true,
      parse: (data: any, lang: any) => {
        const translations: any = JSON.parse(data).records;
        const newTranslations: any = {};
        translations.forEach((translation: any) => {
          newTranslations[translation.key] = translation[lang];
        });

        return newTranslations;
      },
    },
    fallbackLng: false,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;

