import { Box, Button, TablePagination } from '@mui/material';
import React from 'react';
import { BrandType, BrandResponse } from './Brands.type';
import RankItem from './RankItem';
import Alert from '@mui/material/Alert';
import UploadExcel from './UploadExcel';

const URL_API = 'https://www.brandpredictor.ch/';

const Brands = () => {
  const [brands, setBrands] = React.useState<BrandType[]>([]);
  const [changedBrands, setChangedBrands] = React.useState<BrandType[]>([]);
  const [shownBrands, setShownBrands] = React.useState<BrandType[]>([]);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState<'success' | 'error'>(
    'success'
  );

  const getBrands = async () => {
    setIsLoading(true);
    const response = await fetch(`${URL_API}api.php/records/brands`);
    const data: BrandResponse = await response.json();
    //Sort by rank
    const payload = data.records.sort(
      (a: BrandType, b: BrandType) => a.rank_2023 - b.rank_2023
    );
    setBrands(payload);
    setIsLoading(false);
  };

  React.useEffect(() => {
    getBrands();
  }, []);

  React.useEffect(() => {
    setShownBrands(
      brands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [page, rowsPerPage, brands]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeBrand = (brand: BrandType) => {
    const index = changedBrands.findIndex((t) => t.id === brand.id);
    if (index !== -1) {
      const newChangedBrands = [...changedBrands];
      newChangedBrands[index] = brand;
      setChangedBrands(newChangedBrands);
      return;
    } else {
      setChangedBrands([...changedBrands, brand]);
    }
    setBrands(
      brands.map((t) => {
        if (t.id === brand.id) {
          return brand;
        }
        return t;
      })
    );
  };

  const onDeleteBrand = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this brand ?')) {
      await fetch(`${URL_API}api.php/records/brands/${id}`, {
        method: 'DELETE',
      })
        .then((res) => {
          setMessageType('success');
          setMessage('Brand deleted successfully.');
          return res;
        })
        .catch((e) => {
          setMessageType('error');
          setMessage('There was an error while deleting the brand.');

          return e;
        });
      getBrands();
    }
  };

  const deleteAllBrands = async () => {
    const allIds = brands.map((t) => t.id).join(',');
    await fetch(`${URL_API}api.php/records/brands/${allIds}`, {
      method: 'DELETE',
    })
      .then((res) => {
        setMessageType('success');
        setMessage('All brands deleted successfully.');
        return res;
      })
      .catch((e) => {
        setMessageType('error');
        setMessage('There was an error while deleting the brands.');

        return e;
      });
    getBrands();
  };

  const saveBrands = async () => {
    if (changedBrands.length === 0) {
      return;
    }
    changedBrands.forEach(async (brand) => {
      const index = changedBrands.findIndex((t) => t.id === brand.id);
      const newBrand = changedBrands[index];

      await fetch(`${URL_API}api.php/records/brands/${brand.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBrand),
      })
        .then((res) => {
          setMessageType('success');
          setMessage('Translations saved successfully.');

          setTimeout(() => {
            setMessage('');
          }, 3000);
          return res;
        })
        .catch((e) => {
          setMessageType('error');
          setMessage('There was an error while saving the translations.');

          setTimeout(() => {
            setMessage('');
          }, 3000);

          return e;
        });
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <UploadExcel getBrands={getBrands} deleteAllBrands={deleteAllBrands} />
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            minHeight: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Loading...
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            maxWidth: '1400px',
            margin: '0 auto',
            overflowX: 'auto',
            overflowY: 'visible',
            position: 'relative',
          }}
        >
          <RankItem
            key={'brands.header'}
            brand={{
              id: 'brands.header',
              brand: 'Brand',
              aggr_2023: 'Aggr',
              rank_2023: 'Rank',
              dynamism_diff_2023: 'YoY Dynamism',
              trust_diff_2023: 'YoY Trust',
              trust: 'Trust',
              dynamism: 'Dynamism',
              sustainable: 'Sustainable',
            }}
            onChangeBrand={() => {}}
            isHeader
          />
          {shownBrands.map((brand: BrandType, index) => {
            return (
              <RankItem
                key={brand.id}
                brand={brand}
                grey={index % 2 === 0}
                onDeleteBrand={() => {
                  onDeleteBrand(brand.id);
                }}
                onChangeBrand={onChangeBrand}
              />
            );
          })}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={brands.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {changedBrands.length > 0 && (
            <Button
              sx={{
                marginTop: '2rem',
                position: 'fixed',
                bottom: '2rem',
                right: '2rem',
                zIndex: 100,
                backgroundColor: 'secondary.main',
                color: 'white',
                padding: '.5rem 1rem',
                borderRadius: '4px',

                '&:hover': {
                  backgroundColor: 'secondary.dark',
                },
              }}
              onClick={saveBrands}
            >
              Save
            </Button>
          )}
          {message && (
            <Alert
              sx={{
                position: 'fixed',
                bottom: '2rem',
                left: '2rem',
                zIndex: 1000,
              }}
              severity={messageType}
            >
              {message}
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Brands;

