import React from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo } from '../../../assets/logo_black.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex !important',
    justifyContent: 'center', // Centrer horizontalement
    alignItems: 'center', // Centrer verticalement
    height: '100vh',
    flexDirection: 'column', // Afficher les enfants en colonne
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: '0 0px 20px rgba(0, 0, 0, 0.1) !important', // Ajuster la couleur de l'ombre
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3), // Augmentez la marge supérieure pour un espace aéré
  },
  submit: {
    margin: `${theme.spacing(3, 0, 2)} !important`,
    background: `${theme.palette.secondary.main} !important`,
    border: 'none !important',
    outline: 'none !important',
  },
}));

const Login = ({
  connect,
}: {
  connect: (email: string, password: string) => void;
}) => {
  const classes = useStyles();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;
    connect(email, password); // Appelez votre fonction de connexion ici
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper} elevation={3}>
        <Logo />
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Se connecter
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;

