import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as Logo } from '../../../assets/logo_black.svg';
import LogoutIcon from '@mui/icons-material/Logout';

function Navbar({
  openTrads,
  openBrands,
  selected,
  logout,
}: {
  openTrads: () => void;
  openBrands: () => void;
  selected: 'translations' | 'brands';
  logout: () => void;
}) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const pages = [
    { name: 'Translations', onClick: openTrads },
    { name: 'Brands', onClick: openBrands },
  ];

  const pagesMobile = [
    {
      name: 'Translations',
      onClick: () => {
        handleCloseNavMenu();
        openTrads();
      },
    },
    {
      name: 'Brands',
      onClick: () => {
        handleCloseNavMenu();
        openBrands();
      },
    },
    {
      name: 'Logout',
      onClick: () => {
        logout();
      },
    },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#fefefe',
        boxShadow: 'none',
      }}
    >
      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: 'flex',
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
            width: {
              xs: '175px',
              sm: 'auto',
            },
          }}
        >
          <Logo />
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon
              sx={{
                color: 'black',
                fontSize: '2.5rem',
              }}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pagesMobile.map((page) => (
              <MenuItem key={page.name} onClick={page.onClick}>
                <Typography
                  textAlign="center"
                  sx={{
                    color:
                      selected === page.name.toLowerCase()
                        ? 'secondary.main'
                        : 'black',
                  }}
                >
                  {page.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            gap: '2rem',
          }}
        >
          {pages.map((page) => (
            <Button
              key={page.name}
              onClick={page.onClick}
              sx={{
                my: 2,
                display: 'block',
                color:
                  selected === page.name.toLowerCase()
                    ? 'secondary.main'
                    : 'black',
                '&:hover': {
                  backgroundColor: '#fefefe',
                  color: 'secondary.main',
                },
              }}
            >
              {page.name}
            </Button>
          ))}
        </Box>

        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
          <Button
            sx={{
              backgroundColor: '#fefefe',
              color: 'black',
              '&:hover': {
                backgroundColor: '#fefefe',
                color: 'secondary.main',
              },
            }}
            endIcon={<LogoutIcon />}
            onClick={logout}
          >
            Disconnect
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;

