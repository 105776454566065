import React from 'react';
import { Grid } from '@mui/material';
import Hero from './Hero';
import Contact from './Contact';
import Discover from './Discover/Discover';
import Successful from './Successful/Successful';
import Discover2 from './Discover2/Discover2';
import Rank from './Rank/Rank';
import Pricing from './Pricing/Pricing';
import Footer from './Footer';
import { Brand } from './Rank/Rank.type';
import { useRef } from 'react';

export type PricingModelType = 'basic' | 'light' | 'full' | '';

const URL_API = 'https://www.brandpredictor.ch/';

const Home = () => {
  const [isContactOpen, setIsContactOpen] = React.useState(false);
  const [pricingModel, setpricingModel] = React.useState<PricingModelType>('');
  const [brands, setBrands] = React.useState<Brand[]>([]);
  const [dynamismList, setDynamismList] = React.useState<Brand[]>([]);
  //sustainabilty
  const [sustainableList, setSustainableList] = React.useState<Brand[]>([]);
  const [trustList, setTrustList] = React.useState<Brand[]>([]);
  const [visibleSection, setVisibleSection] = React.useState('');
  const heroRef = useRef<HTMLDivElement>(null);
  const methodologyRef = useRef<HTMLDivElement>(null);
  const keyRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const openContact = (pricingModel?: PricingModelType) => {
    setIsContactOpen(true);
    if (pricingModel) {
      changePricingModel(pricingModel);
    }
    //smooth scroll
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const changePricingModel = (pricingModel: PricingModelType) => {
    setpricingModel(pricingModel);
  };

  const closeContact = () => {
    setIsContactOpen(false);
  };

  const getBrands = async () => {
    const url = `${URL_API}api.php/records/brands`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        orderBrands(data.records as Brand[]);
      });
  };

  const orderBrands = (newBrands: Brand[]) => {
    const payloadDynmism = newBrands
      .sort((a, b) => {
        if (!a?.dynamism) return 1;
        if (!b?.dynamism) return -1;
        return parseFloat(b.dynamism) - parseFloat(a.dynamism);
      })
      .slice(0, 10);

    const payloadSustainability = newBrands
      .sort((a, b) => {
        if (!a?.sustainable) return 1;
        if (!b?.sustainable) return -1;
        return parseFloat(b.sustainable) < parseFloat(a.sustainable) ? -1 : 1;
      })
      .slice(0, 10);
    const payloadTrust = newBrands
      .sort((a, b) => {
        if (!a?.trust) return 1;
        if (!b?.trust) return -1;
        return parseFloat(b.trust) < parseFloat(a.trust) ? -1 : 1;
      })
      .slice(0, 10);

    setBrands(newBrands);
    setDynamismList(payloadDynmism);
    setSustainableList(payloadSustainability);
    setTrustList(payloadTrust);
  };

  React.useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callBack = (entries: any) => {
    entries.forEach(function (entry: any) {
      if (entry.isIntersecting) {
        setVisibleSection(entry.target.id);
      }
    });
  };

  let observer = new IntersectionObserver(callBack, {
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (pricingRef.current) observer.observe(pricingRef.current as Element);
    if (keyRef.current) observer.observe(keyRef.current as Element);
    if (methodologyRef.current)
      observer.observe(methodologyRef.current as Element);
    if (footerRef.current) observer.observe(footerRef.current as Element);
    if (heroRef.current) observer.observe(heroRef.current as Element);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingRef, keyRef, methodologyRef]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Grid
        item
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          transition: 'all 1s ease',
        }}
      >
        <Hero
          isContactOpen={isContactOpen}
          openContact={() => {
            openContact();
          }}
          visibleSection={visibleSection}
          heroRef={heroRef}
        />
        <Contact
          open={isContactOpen}
          closeContact={closeContact}
          pricingModel={pricingModel}
          changePricingModel={changePricingModel}
        />
      </Grid>
      <Discover
        openContact={() => {
          openContact();
        }}
        brands={brands}
        methodologyRef={methodologyRef}
      />
      <Successful
        openContact={() => {
          openContact();
        }}
        keyRef={keyRef}
      />
      <Discover2 />
      <Rank
        openContact={() => {
          openContact();
        }}
        dynamismList={dynamismList}
        sustainableList={sustainableList}
        trustList={trustList}
      />
      <Pricing
        openContact={() => {
          openContact();
        }}
        pricingRef={pricingRef}
      />
      <Footer
        openContact={() => {
          openContact();
        }}
        footerRef={footerRef}
      />
    </Grid>
  );
};

export default Home;

