import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import { HorizontalCarouselCardProps } from './HorizontalCarousel.type';
import HorizontalCarouselCard from './HorizontalCarouselCard';
import ArrowButton from '../../ArrowButton';

interface HorizontalCarouselProps {
  items: HorizontalCarouselCardProps[];
}

const HorizontalCarousel: React.FC<HorizontalCarouselProps> = ({ items }) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(true);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);

  const handleScroll = () => {
    if (carouselRef.current) {
      const scrollLeft = carouselRef.current.scrollLeft;
      const maxScrollLeft =
        carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
      setCanScrollRight(scrollLeft < maxScrollLeft);
      setCanScrollLeft(scrollLeft > 0 ? true : false);
    }
  };

  const handleScrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        left: carouselRef.current.scrollLeft + 390,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        left:
          carouselRef.current.scrollLeft < 390
            ? 0
            : carouselRef.current.scrollLeft - 390,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box
      sx={{
        width: 'calc(100% + 2rem)',
        height: 'fit-content',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          overflowX: 'scroll',
          overflowY: 'hidden',
          paddingBottom: '1rem',
          scrollBehavior: 'smooth',
          scrollSnapType: 'x mandatory',
        }}
        ref={carouselRef}
        onScroll={handleScroll}
      >
        {items.map((item, index) => {
          return (
            <HorizontalCarouselCard
              key={index}
              title={item.title}
              description={item.description}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
          margin: '3rem 0 6rem',
        }}
      >
        <ArrowButton
          reverse
          onClick={handleScrollLeft}
          disabled={!canScrollLeft}
        />
        <ArrowButton disabled={!canScrollRight} onClick={handleScrollRight} />
      </Box>
    </Box>
  );
};

export default HorizontalCarousel;

