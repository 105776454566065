import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Arrow } from '../../assets/arrow_black.svg';

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  reverse?: boolean;
}

const ArrowButton: React.FC<ButtonProps> = ({ onClick, disabled, reverse }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        padding: '.4rem .6rem',
        borderRadius: '10px',
        border: '1px solid #000',
        marginRight: '1rem',
        cursor: 'pointer',
        pointerEvents: disabled ? 'none' : 'all',
        opacity: disabled ? 0.5 : 1,
        transition: 'all .2s ease',

        '&:hover': {
          backgroundColor: 'black',
          border: '1px solid black',
          svg: {
            fill: 'white',
            path: {
              fill: 'white',
            },
          },
        },
      }}
      onClick={onClick}
    >
      <Arrow
        style={{
          width: '1.7rem',
          height: '.7rem',
          transform: reverse ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'all .2s ease',
        }}
      />
    </Box>
  );
};

export default ArrowButton;

