import { Box, Button, Grid, TextField } from '@mui/material';
import React from 'react';
import { BrandType, BrandHeaderType } from './Brands.type';
import DeleteIcon from '@mui/icons-material/Delete';

const TextInput = ({
  brand,
  onChange,
  field,
}: {
  brand: BrandType;
  onChange: (brand: BrandType) => void;
  field: keyof BrandType;
}) => {
  const value = ['trust', 'sustainable', 'dynamism'].includes(field)
    ? Math.round(parseFloat(brand[field] as string) * 100) / 100
    : brand[field];

  return (
    <TextField
      sx={{
        width: '100%',
      }}
      variant="standard"
      defaultValue={value}
      onBlur={(e) => {
        if (e.target.value === brand[field]) {
          return;
        }
        onChange({
          ...brand,
          [field]: e.target.value,
        });
      }}
    />
  );
};

interface DiscoverRankProps {
  brand: BrandType | BrandHeaderType;
  isHeader?: boolean;
  grey?: boolean;
  onChangeBrand: (brand: BrandType) => void;
  onDeleteBrand?: () => void;
}
const RankItem: React.FC<DiscoverRankProps> = ({
  brand,
  isHeader,
  grey,
  onDeleteBrand,
  onChangeBrand,
}) => {
  return (
    <Grid
      sx={{
        maxHeight: brand ? '500px' : '0px',
        overflow: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: grey ? 'rgba(0,0,0,0.1)' : 'transparent',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          padding: isHeader ? '1rem 2rem  ' : '2rem',
          margin: '0',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '10%',
            minWidth: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {isHeader ? (
            brand?.rank_2023
          ) : (
            <TextInput
              brand={brand as BrandType}
              onChange={(brand) => {
                onChangeBrand(brand);
              }}
              field="rank_2023"
            />
          )}
        </Box>
        <Box
          sx={{
            width: '30%',
            minWidth: '125px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {isHeader ? (
            brand?.brand
          ) : (
            <TextInput
              brand={brand as BrandType}
              onChange={(brand) => {
                onChangeBrand(brand);
              }}
              field="brand"
            />
          )}
        </Box>
        <Box
          sx={{
            width: '10%',
            minWidth: '125px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {isHeader ? (
            brand.dynamism_diff_2023
          ) : (
            <TextInput
              brand={brand as BrandType}
              onChange={(brand) => {
                onChangeBrand(brand);
              }}
              field="dynamism_diff_2023"
            />
          )}
        </Box>
        <Box
          sx={{
            width: '10%',
            minWidth: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {isHeader ? (
            brand.trust_diff_2023
          ) : (
            <TextInput
              brand={brand as BrandType}
              onChange={(brand) => {
                onChangeBrand(brand);
              }}
              field="trust_diff_2023"
            />
          )}
        </Box>
        <Box
          sx={{
            width: '10%',
            minWidth: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {isHeader ? (
            brand.dynamism
          ) : (
            <TextInput
              brand={brand as BrandType}
              onChange={(brand) => {
                onChangeBrand(brand);
              }}
              field="dynamism"
            />
          )}
        </Box>
        <Box
          sx={{
            width: '10%',
            minWidth: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {isHeader ? (
            brand.sustainable
          ) : (
            <TextInput
              brand={brand as BrandType}
              onChange={(brand) => {
                onChangeBrand(brand);
              }}
              field="sustainable"
            />
          )}
        </Box>
        <Box
          sx={{
            width: '10%',
            minWidth: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {isHeader ? (
            brand.trust
          ) : (
            <TextInput
              brand={brand as BrandType}
              onChange={(brand) => {
                onChangeBrand(brand);
              }}
              field="trust"
            />
          )}
        </Box>
        <Box
          sx={{
            width: '10%',
            minWidth: '100px',
          }}
        >
          {!isHeader && (
            <Button
              variant="contained"
              color="error"
              sx={{
                border: 'none',
              }}
              startIcon={<DeleteIcon />}
              onClick={onDeleteBrand}
            >
              Delete
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default RankItem;

