import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Title from '../common/Title';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import { Brand } from './Rank.type';
import RankTable from './RankTable';
import SwipeableViews from 'react-swipeable-views';
import CustomButton from '../common/CustomButton';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        width: '100%',
      }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      maxWidth: 'max-content',
      fontFamily: 'Arial',
      fontWeight: 400,
      fontSize: '1.5rem',
      textTransform: 'none',
      minWidth: 'max-content',
    },
  };
}

const Rank = ({
  openContact,
  dynamismList,
  sustainableList,
  trustList,
}: {
  openContact: () => void;
  dynamismList: Brand[];
  sustainableList: Brand[];
  trustList: Brand[];
}) => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5.65rem 2rem 3rem',
        width: '100%',
      }}
    >
      <Title
        title="rank.title"
        dark
        topTitle="rank.topTitle"
        red
        big
        sx={{
          marginBottom: '4rem',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
          maxWidth: '1250px !important',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '1200px !important',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            '.MuiTabs-root': {
              width: '100%',
            },
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            sx={{
              '.MuiTabs-flexContainer': {
                justifyContent: 'space-between',
                width: 'auto',
                maxWidth: '100vw',
                margin: '0 auto',
              },

              '.MuiTabs-scroller': {
                paddingBottom: '.5rem',
              },
            }}
            TabIndicatorProps={{
              sx: {
                height: 8,
                backgroundColor: 'transparent',
                '&::before': {
                  position: 'absolute',
                  top: '0',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  content: '""',
                  height: '8px !important',
                  width: '8px !important',
                  borderRadius: '50%',
                  backgroundColor: 'secondary.main',
                },
              },
            }}
          >
            <Tab
              label={isMobile ? t('rank.1.title_mobile') : t('rank.1.title')}
              {...a11yProps(0)}
            />
            <Tab
              label={isMobile ? t('rank.2.title_mobile') : t('rank.2.title')}
              {...a11yProps(1)}
            />
            <Tab
              label={isMobile ? t('rank.3.title_mobile') : t('rank.3.title')}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <SwipeableViews
          axis={'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          style={{
            width: '100%',
          }}
        >
          <CustomTabPanel value={value} index={0}>
            <RankTable items={dynamismList} field="dynamism" />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <RankTable items={sustainableList} field="sustainable" />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <RankTable items={trustList} field="trust" />
          </CustomTabPanel>
        </SwipeableViews>
      </Box>
      <CustomButton title={t('rank.button')} dark onClick={openContact} />
    </Grid>
  );
};

export default Rank;

