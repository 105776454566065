import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './muiTheme';
import './locales/i18n';
import router from './router';
import { RouterProvider } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ThemeProvider>
  );
};

export default App;

