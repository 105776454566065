import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TitleProps {
  title: string;
  dark?: boolean;
  variant?: 'h1' | 'h2';
  maxWidth?: string;
  sx?: any;
  align?: 'left' | 'center' | 'right';
  topTitle?: string;
  big?: boolean;
  red?: boolean;
  smallTopTitle?: boolean;
  smallDesktop?: boolean;
  hideTopTitleMobile?: boolean;
}

const Title: React.FC<TitleProps> = ({
  title,
  topTitle,
  dark,
  variant,
  maxWidth,
  sx,
  align,
  big,
  red,
  smallTopTitle,
  smallDesktop,
  hideTopTitleMobile,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems:
          align === 'left'
            ? 'flex-start'
            : align === 'right'
            ? 'flex-end'
            : 'center',
      }}
    >
      {topTitle && (
        <Typography
          variant="body2"
          sx={{
            color: dark ? 'black' : 'white',
            fontWeight: 500,
            display: hideTopTitleMobile ? { xs: 'none', sm: 'block' } : 'block',
            textAlign: align || 'center',
            lineHeight: '155%',
            fontSize: smallTopTitle ? '1.5rem' : { xs: '2rem', sm: '2.5rem' },
            maxWidth: maxWidth || 'none',

            '& sup': {
              fontSize: '.6em',
              lineHeight: '.5',
            },
          }}
          component="span"
        >
          <div dangerouslySetInnerHTML={{ __html: t(topTitle) }}></div>
        </Typography>
      )}
      <Typography
        variant={variant || 'h2'}
        sx={{
          color: red ? 'secondary.main' : dark ? 'black' : 'white',
          fontWeight: '500',
          textAlign: align || 'center',
          lineHeight: '105%',
          margin: '.75rem 0 1rem',
          maxWidth: maxWidth || 'none',
          fontSize: big
            ? {
                xs: smallDesktop ? '2rem' : '4.75rem',
                sm: smallDesktop ? '2.5rem' : '5.75rem',
                md: '6.5rem',
              }
            : {
                xs: '2rem',
                sm: '2.5rem',
                md: '3.75rem',
              },
          ...sx,

          '& sup': {
            fontSize: '.6em',
            lineHeight: '.5',
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: t(title) }}></div>
      </Typography>
    </Box>
  );
};

export default Title;

