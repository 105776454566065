import React from 'react';
import { Button } from '@mui/material';
import Close from '../../assets/close.svg';

interface IconButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  sx?: any;
}

const IconButton: React.FC<IconButtonProps> = ({ onClick, disabled, sx }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onClick}
      sx={{
        color: 'black',
        borderColor: 'black',
        padding: {
          xs: '.5rem',
          sm: '1rem',
        },
        fontSize: '1rem',
        fontFamily: 'Arial',
        borderRadius: '10px',
        border: '1px solid black',
        textTransform: 'Capitalize',
        fontWeight: 700,
        minWidth: 'auto',
        opacity: disabled ? 0.5 : 1,
        transition: 'all 1s ease',
        ...sx,

        '& span': {
          marginLeft: '.75rem',
          transition: 'margin-left 0.2s ease-in-out',
        },

        '&:hover': {
          borderColor: '#FFF',
        },
      }}
    >
      <img src={Close} alt="close" />
    </Button>
  );
};

export default IconButton;

