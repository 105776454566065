import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: ['BaikalExpended', 'Arial', 'Baikal', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#E60000',
    },
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          // Name of the rule
          background: 'transparent',
          border: '1px solid #FFF',
          color: 'white',

          '&:hover': {
            borderColor: '#FFF',
          },
        },
      },
    },
  },
});

export default theme;

