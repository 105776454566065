import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { FormControl } from '@mui/base';
import { InputBase, InputLabel } from '@mui/material';

const Input = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: '0',
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    fontSize: 16,
    padding: '.625rem .75rem',
    width: '100%',
    fontFamily: 'Arial',
    fontWeight: 700,

    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface InputProps {
  label: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  autocomplete?: string;
}

const CustomInput: React.FC<InputProps> = ({
  label,
  onChange,
  autocomplete,
}) => {
  return (
    <FormControl>
      <InputLabel
        shrink
        htmlFor={`${label}-input`}
        sx={{
          color: 'black',
          fontFamily: 'Baikal',
          fontSize: { xs: '1.3rem', sm: '1rem' },
          fontWeight: 300,
          lineHeight: '100%',
          letterSpacing: '0.0375rem',
          textTransform: 'uppercase',
          width: '100%',
        }}
      >
        {label}
      </InputLabel>
      <Input
        id={`${label}-input`}
        name={label}
        onChange={onChange}
        sx={{
          width: '100%',
        }}
        autoComplete={autocomplete}
        type={label === 'email' ? 'email' : 'text'}
      />
    </FormControl>
  );
};

export default CustomInput;

