import { Box, Grid } from '@mui/material';
import React from 'react';
import Title from '../common/Title';
import PricingCard from './PricingCard';
import { ReactComponent as PricingBasic } from '../../assets/pricing_basic.svg';
import { ReactComponent as PricingLight } from '../../assets/pricing_light.svg';
import { ReactComponent as PricingFull } from '../../assets/pricing_full.svg';
import { PricingModelType } from '../Home';

const Pricing = ({
  openContact,
  pricingRef,
}: {
  openContact: (pricingModel?: PricingModelType) => void;
  pricingRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5.65rem 2rem',
        width: '100%',
      }}
      id="pricing"
      ref={pricingRef}
    >
      <Title
        title="pricing.title"
        dark
        topTitle="pricing.topTitle"
        red
        big
        sx={{
          marginBottom: '4rem',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: '1rem',
          width: '100%',
          maxWidth: '1250px !important',
          overflowX: 'scroll',
          scrollSnapType: 'x mandatory',
        }}
      >
        <PricingCard
          title="pricing.basic.title"
          price="pricing.basic.price"
          description="pricing.basic.description"
          features={[
            {
              title: 'pricing.basic.features.1.title',
              description: 'pricing.basic.features.1.description',
            },
          ]}
          button="pricing.basic.button"
          Icon={PricingBasic}
          onClick={() => openContact('basic')}
        />
        <PricingCard
          title="pricing.light.title"
          beforePrice="pricing.light.beforePrice"
          price="pricing.light.price"
          description="pricing.light.description"
          onClick={() => openContact('light')}
          features={[
            {
              title: 'pricing.light.features.1.title',
              description: 'pricing.light.features.1.description',
            },
            {
              title: 'pricing.light.features.2.title',
              description: 'pricing.light.features.2.description',
            },
            {
              title: 'pricing.light.features.3.title',
              description: 'pricing.light.features.3.description',
            },
            {
              title: 'pricing.light.features.4.title',
              description: 'pricing.light.features.4.description',
            },
            {
              title: 'pricing.light.features.5.title',
              description: 'pricing.light.features.5.description',
            },
          ]}
          button="pricing.light.button"
          Icon={PricingLight}
        />
        <PricingCard
          title="pricing.full.title"
          beforePrice="pricing.full.beforePrice"
          price="pricing.full.price"
          description="pricing.full.description"
          onClick={() => openContact('full')}
          features={[
            {
              title: 'pricing.full.features.1.title',
              description: 'pricing.full.features.1.description',
            },
            {
              title: 'pricing.full.features.2.title',
              description: 'pricing.full.features.2.description',
            },
            {
              title: 'pricing.full.features.3.title',
              description: 'pricing.full.features.3.description',
            },
            {
              title: 'pricing.full.features.4.title',
              description: 'pricing.full.features.4.description',
            },
          ]}
          button="pricing.full.button"
          Icon={PricingFull}
        />
      </Box>
    </Grid>
  );
};

export default Pricing;

