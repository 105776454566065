import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';

const NAV_ITEMS = [
  {
    title: 'nav.method',
    link: 'methodology',
  },
  {
    title: 'nav.key',
    link: 'key',
  },
  {
    title: 'nav.pricing',
    link: 'pricing',
  },
];

interface ItemProps {
  title: string;
  link: string;
  isSelected?: boolean;
  noSelected?: boolean;
}

interface NavProps {
  selected?: string;
  sx?: any;
  openContact?: () => void;
}

const Item: React.FC<ItemProps> = ({ title, link, isSelected, noSelected }) => {
  const { t } = useTranslation();

  return (
    <Link
      href={`#${link}`}
      style={{
        textDecoration: 'none',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: 'white',
          fontFamily: 'Arial',
          fontSize: '.85rem',
          fontWeight: 400,
          letterSpacing: '0.72px',
          opacity: isSelected || noSelected ? '1' : '0.6',
          transition: 'opacity 0.2s ease',
          position: 'relative',

          '&:hover': {
            opacity: '1',
            '&:after': {
              opacity: 1,
            },
          },

          '&:after': {
            content: '""',
            opacity: !noSelected && isSelected ? '1' : '0',
            position: 'absolute',
            height: '2px',
            width: '2px',
            background: 'white',
            bottom: '-0.4rem',
            left: '50%',
            transform: 'translateX(-50%)',
            transition: 'opacity 0.2s ease',
          },
        }}
      >
        {t(title)}
      </Typography>
    </Link>
  );
};

const Separator: React.FC = () => {
  return (
    <Box
      sx={{
        width: '1px',
        height: '1.5rem',
        background: 'rgba(255, 255, 255, 0.20)',
        margin: '0 2rem',
      }}
    />
  );
};

const Nav: React.FC<NavProps> = ({ selected, sx, openContact }) => {
  const [hasScroll, setHasScroll] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '3rem',
        zIndex: selected === 'footer' ? 0 : 1000,
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        transition: 'all  0.25s ease-in-out',
        opacity: selected === 'footer' ? '0' : '1',
        pointerEvents: selected === 'footer' ? 'none' : 'all',
      }}
    >
      <Box
        sx={{
          padding: '1rem 2.25rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '10px',
          background: !hasScroll ? 'rgba(217, 217, 217, 0.10)' : 'black',
          backdropFilter: !hasScroll ? 'blur(7.5px)' : '',
          width: { xs: 'calc(100% - 4rem)', sm: 'fit-content' },
          ...sx,
        }}
      >
        {NAV_ITEMS.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Item
                title={item.title}
                link={item.link}
                isSelected={item.link === selected ? true : false}
              />
              {index !== NAV_ITEMS.length - 1 && <Separator />}
            </React.Fragment>
          );
        })}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: '2rem',
          bottom: { xs: '5rem', sm: '0' },
          width: '3rem',
          height: '3rem',
          background: '#e60001',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: !hasScroll ? 'default' : 'pointer',
          transition: 'all 0.2s ease',
          opacity: !hasScroll ? '0 !important' : '1',
          pointerEvents: !hasScroll ? 'none' : 'all',

          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
        onClick={openContact}
      >
        <EmailIcon
          sx={{
            color: 'white',
          }}
        />
      </Box>
    </Box>
  );
};

export default Nav;

