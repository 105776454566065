import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../common/CustomButton';
import { Brand } from './Rank.type';

const showNumber = (number: number, type: 'number' | 'percent') => {
  if (type === 'number') {
    return Math.floor(number * 10) / 10;
  } else {
    return `${Math.floor(number * 10) / 10}%`;
  }
};

interface DiscoverRankProps {
  brand?: Brand | null;
  black?: boolean;
  fullWidth?: boolean;
  borderBottom?: boolean;
  noBtn?: boolean;
  rank?: number | string;
  isHeader?: boolean;
  field: 'dynamism' | 'trust' | 'sustainable';
}
const RankItem: React.FC<DiscoverRankProps> = ({
  brand,
  black,
  fullWidth,
  borderBottom,
  noBtn,
  rank,
  isHeader,
  field,
}) => {
  const [lastBrand, setLastBrand] = React.useState<Brand | null>(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (brand) {
      setLastBrand(brand);
    }
  }, [brand]);

  return (
    <Grid
      sx={{
        maxHeight: '500px',
        overflow: 'hidden',
        transition: 'all .5s ease-in-out',
        width: '100%',
        maxWidth: fullWidth ? '100%' : '800px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: borderBottom ? '1px solid black' : 'none',
        margin: fullWidth ? '0 0 3rem' : 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: isHeader
            ? '1rem'
            : fullWidth
            ? '3rem'
            : brand
            ? '1.75rem'
            : 0,
          margin: fullWidth ? '0' : brand || isHeader ? '4rem auto' : 0,
          borderBottom: brand || isHeader ? '1px solid white' : 'none',
          width: '100%',
          transition: 'all .5s ease-in-out',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: isHeader
              ? { xs: '.85rem', sm: '1rem' }
              : { xs: '2rem', sm: '3.25rem' },
            fontWeight: 500,
            textTransform: 'uppercase',
            color: black ? 'black' : 'white',
            width: { xs: '40%', sm: '25%' },
            textAlign: 'left',
            opacity: brand || isHeader ? 1 : 0,
            transition: 'all .5s ease-in-out',
          }}
        >
          {isHeader ? t('rank.rank2023') : rank || 'XX'}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: isHeader ? '.85rem' : '1rem', sm: '1rem' },
            fontWeight: 500,
            textTransform: 'uppercase',
            color: black ? 'black' : 'white',
            width: { xs: '35%', sm: '25%' },
            textAlign: 'left',
            opacity: brand || isHeader ? 1 : 0,
            transition: 'all .5s ease-in-out',
          }}
        >
          {isHeader
            ? t('rank.brandName')
            : brand?.brand || lastBrand?.brand || 'Brand'}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: '.75rem', sm: '1rem' },
            fontWeight: 500,
            textTransform: 'uppercase',
            color: black ? 'black' : 'white',
            width: { xs: '0%', sm: '25%' },
            textAlign: 'right',
            opacity: brand || isHeader ? 1 : 0,
            transition: 'all .5s ease-in-out',
          }}
        ></Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: isHeader ? '.85rem' : '1rem', sm: '1rem' },
            fontWeight: 500,
            textTransform: 'uppercase',
            color: black ? 'black' : 'white',
            width: { xs: '25%' },
            textAlign: 'right',
            opacity: brand || isHeader ? 1 : 0,
            transition: 'all .5s ease-in-out',
          }}
        >
          {isHeader
            ? t(`rank.${field}`)
            : showNumber(
                parseFloat(brand?.[field] as string) || 0,
                field === 'dynamism' ? 'number' : 'percent'
              )}
        </Typography>
      </Box>
      {!noBtn && (
        <CustomButton
          title={t('discover.requestButton')}
          sx={{
            opacity: brand || isHeader ? 1 : 0,
            maxHeight: brand || isHeader ? '100px' : 0,
            overflow: 'hidden',
            transition: 'all .5s ease-in-out',
          }}
        />
      )}
    </Grid>
  );
};

export default RankItem;

