import { Box, Typography } from '@mui/material';
import React, { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceFeature } from './Pricing.type';
import { ReactComponent as Feature } from '../../assets/feature.svg';
import CustomButton from '../common/CustomButton';

interface PricingCardProps {
  Icon: React.FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  title: string;
  description: string;
  features: PriceFeature[];
  button: string;
  price: string;
  beforePrice?: string;
  onClick: () => void;
}

const PricingCard: React.FC<PricingCardProps> = ({
  Icon,
  title,
  description,
  features,
  button,
  price,
  beforePrice,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        minWidth: '300px',
        border: '1px solid rgba(0, 0, 0, 0.10)',
        borderRadius: '10px',
        padding: '1.5rem',
        backgroundColor: 'white',
        transition: 'all .2s ease',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        scrollSnapAlign: 'start',
        svg: {
          width: '4rem',
          height: '4rem',
          fill: '#E60000',
          transition: 'all .2s ease',

          circle: {
            transition: 'all .2s ease',
          },
          path: {
            transition: 'all .2s ease',
          },
        },

        '&:hover': {
          backgroundColor: '#E60000',

          svg: {
            circle: {
              fill: 'white',
            },
            path: {
              fill: '#E60000',
            },
          },

          '& h3': {
            color: 'white',
          },

          '& p': {
            color: 'white',
          },

          '& .MuiButtonBase-root': {
            backgroundColor: 'white',
            color: '#E60000',
            transition: 'all .2s ease',
          },
        },
      }}
      onClick={onClick}
    >
      <Box>
        <Icon
          style={{
            width: '4rem',
            height: '4rem',
          }}
        />
        <Typography
          variant="h3"
          sx={{
            fontSize: '2.75rem',
            fontWeight: 500,
            transition: 'all .2s ease',
            color: '#E60000',
            lineHeight: 'normal',
            marginTop: '.5rem',
            marginBottom: '.25rem',
          }}
        >
          {t(title)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: '1.25rem',
            fontWeight: 400,
            color: 'black',
            lineHeight: 'normal',
            marginBottom: '2rem',
            transition: 'all .2s ease',
            fontFamily: 'Arial',
          }}
        >
          {t(description)}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            transition: 'all .2s ease',
          }}
        >
          {beforePrice && (
            <Typography
              variant="body2"
              sx={{
                fontSize: '1rem',
                fontWeight: 500,
                color: 'black',
                lineHeight: 'normal',
                marginRight: '.75rem',
                transition: 'all .2s ease',
              }}
            >
              {t(beforePrice)}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{
              fontSize: '1.75rem',
              fontWeight: 500,
              color: 'black',
              lineHeight: 'normal',
              transition: 'all .2s ease',
            }}
          >
            {t(price)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '1px',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.10)',
            margin: '1.5rem 0',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          {features.map((feature, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: '1.5rem',
                width: '100%',
              }}
            >
              <Feature
                style={{
                  width: '2.5rem',
                  height: '2.5rem',

                  minWidth: '2.5rem',
                  minHeight: '2.5rem',
                }}
              />

              <Box
                sx={{
                  marginLeft: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: 'black',
                    fontFamily: 'Arial',
                    fontSize: '.9rem',
                    fontWeight: 700,
                    lineHeight: '200.286%',
                    letterSpacing: '-0.01313rem',
                  }}
                >
                  {t(feature.title)}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'black',
                    lineHeight: '128.571%',
                    fontSize: '.9rem',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    opacity: 0.4,
                  }}
                >
                  {t(feature.description)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            height: '1px',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.10)',
            marginBottom: '1.5rem',
          }}
        />
        <CustomButton
          title={t(button)}
          sx={{
            backgroundColor: '#E60000',
            color: 'white',
            width: '100%',
            border: 'none !important',
            marginTop: 'auto',
            padding: '.75rem 1rem',
            fontSize: '.85rem',
          }}
        />
      </Box>
    </Box>
  );
};

export default PricingCard;

