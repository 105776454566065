import React from 'react';
import { Grid } from '@mui/material';
import SmallBackgroundDiscover from '../../assets/background_push.jpg';
import BackgroundDiscover from '../../assets/background_push@2x.jpg';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DiscoverSelect from './DiscoverSelect';
import DiscoverRank from './DiscoverRank';
import Title from '../common/Title';
import { Brand } from '../Rank/Rank.type';

interface DiscoverCardProps {
  changeBrand: (brand: Brand) => void;
  brands: Brand[];
  brandSelected: Brand | null;
  openContact: () => void;
}

const DiscoverCard: React.FC<DiscoverCardProps> = ({
  changeBrand,
  brands,
  brandSelected,
  openContact,
}) => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: {
          xs: '100%',
          sm: '90%',
        },
        justifyContent: 'center',
        alignItems: 'center',
        padding: {
          xs: '2.5rem 1.5rem 4rem',
          sm: '4rem 2rem ',
          md: '5.65rem 2rem',
        },
        width: '100%',
        borderRadius: '10px',
        backgroundImage: {
          xs: `url(${SmallBackgroundDiscover})`,
          xl: `url(${BackgroundDiscover})`,
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Title title="discover.title" maxWidth="950px" />
      <Typography
        variant="body1"
        sx={{
          color: 'white',
          fontWeight: 400,
          textAlign: 'center',
          lineHeight: '137%',
          margin: '.75rem 0 2.75rem',
          maxWidth: '625px',
          fontFamily: 'Arial',

          '& sup': {
            fontSize: '.6em',
            lineHeight: '.5',
          },
        }}
      >
        {t('discover.description')}
      </Typography>
      <DiscoverSelect changeBrand={changeBrand} brands={brands} />
      <DiscoverRank brand={brandSelected} openContact={openContact} />
    </Grid>
  );
};

export default DiscoverCard;

