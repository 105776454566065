import React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import SmallBackgroundSuccessful from '../assets/background_black.jpg';
import BackgroundSuccessful from '../assets/background_black@2x.jpg';
import Title from './common/Title';
import { useTranslation } from 'react-i18next';
import CustomButton from './common/CustomButton';
import Logo from '../assets/logo_havas_x_intervista_black.svg';

const SmallLink = ({
  title,
  margin,
  link,
}: {
  title: string;
  margin?: boolean;
  link: string;
}) => {
  return (
    <Typography
      variant="body2"
      sx={{
        color: 'black',
        fontSize: '.9rem',
        opacity: 0.4,
        fontFamily: 'Arial',
        fontWeight: 400,
        lineHeight: '140%',
        textDecoration: 'none',
        margin: margin ? '0 1rem ' : '0',
      }}
      component="a"
      href={link}
      target="_blank"
    >
      {title}
    </Typography>
  );
};

const BigLink = ({
  title,
  margin,
  link,
}: {
  title: string;
  margin?: boolean;
  link: string;
}) => {
  return (
    <Typography
      variant="body2"
      sx={{
        color: 'black',
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: '140%',
        textDecoration: 'none',
      }}
      component="a"
      href={link}
      target="_blank"
    >
      {title}
    </Typography>
  );
};

const Footer = ({
  openContact,
  footerRef,
}: {
  openContact: () => void;
  footerRef: any;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      item
      container
      sx={{
        paddingTop: '5rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: {
          xs: `url(${SmallBackgroundSuccessful})`,
          xl: `url(${BackgroundSuccessful})`,
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      ref={footerRef}
      id="footer"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '0 2rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Title
          title={'footer.title'}
          topTitle="footer.topTitle"
          maxWidth="1350px"
          big
          smallDesktop
          smallTopTitle
          hideTopTitleMobile
          sx={{
            marginBottom: '3rem',
          }}
        />
        <Typography
          variant="body2"
          sx={{
            color: 'white',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: '155%',
            fontSize: '1rem',
            maxWidth: '570px',
            marginBottom: '3rem',
          }}
          component="span"
        >
          <div
            dangerouslySetInnerHTML={{ __html: t('footer.description') }}
            style={{
              fontFamily: 'Arial',
            }}
          ></div>
        </Typography>
        <CustomButton
          title={t('footer.button')}
          red
          sx={{
            marginBottom: '6rem',
          }}
          onClick={openContact}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 'calc(100% - 2rem)',
          margin: '1rem auto',
          backgroundColor: 'white',
          borderRadius: '10px',
          alignItems: 'center',
          padding: { xs: '1.5rem', sm: '2.5rem' },
          transition: 'all 1s ease',
          position: 'relative',

          img: {
            width: {
              xs: '150px',
              sm: 'auto',
              md: '200px',
            },
          },
        }}
      >
        <img src={Logo} alt="Logo" />
        <Box
          sx={{
            width: '100%',
            height: '1px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            marginTop: '2rem',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: { md: 'space-between', xs: 'center' },
            maxWidth: '900px',
            width: '100%',
            margin: '2.5rem auto',
            flexDirection: { xs: 'column', md: 'row' },
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: { xs: '1rem', sm: '2rem' },
          }}
        >
          <BigLink
            title={t('footer.linkedin')}
            link="https://www.linkedin.com/company/havasswitzerland/"
          />
          {mobile ? (
            <>
              <BigLink
                title={t('footer.youtube')}
                link="https://www.youtube.com/channel/UCuzXI8qp9Ia6pcwd0IKwo9A"
              />
              <BigLink
                title={t('footer.website')}
                link="https://swiss.havas.com/"
              />
            </>
          ) : (
            <>
              <BigLink
                title={t('footer.website')}
                link="https://swiss.havas.com/"
              />
              <BigLink
                title={t('footer.youtube')}
                link="https://www.youtube.com/channel/UCuzXI8qp9Ia6pcwd0IKwo9A"
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '1px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            marginBottom: '1.5rem',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <SmallLink
            title={t('footer.cookies')}
            link="https://swiss.havas.com/cookie-policy/"
          />
          <SmallLink
            title={t('footer.terms')}
            margin
            link="https://swiss.havas.com/terms-of-use/"
          />
          <SmallLink
            title={t('footer.data')}
            link="https://swiss.havas.com/data-protection-policy/"
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default Footer;

