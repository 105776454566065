import React from 'react';
import { Grid, Typography } from '@mui/material';
import SmallBackgroundSuccessful from '../../assets/background_black.jpg';
import BackgroundSuccessful from '../../assets/background_black@2x.jpg';
import Title from '../common/Title';
import { useTranslation } from 'react-i18next';
import SuccessImgEn from '../../assets/brandpredictor_medium_en.svg';
import SuccessImgFr from '../../assets/brandpredictor_medium_fr.svg';
import SuccessImgDe from '../../assets/brandpredictor_medium_de.svg';
import CustomButton from '../common/CustomButton';

const Successful = ({
  openContact,
  keyRef,
}: {
  openContact: () => void;
  keyRef: React.RefObject<HTMLDivElement>;
}) => {
  const { t, i18n } = useTranslation();
  const successImgs = {
    en: SuccessImgEn,
    fr: SuccessImgFr,
    de: SuccessImgDe,
  };
  return (
    <Grid
      item
      container
      sx={{
        padding: { xs: '4rem 2rem', sm: '5rem 2rem' },
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundImage: {
          xs: `url(${SmallBackgroundSuccessful})`,
          xl: `url(${BackgroundSuccessful})`,
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      id="key"
      ref={keyRef}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          paddingLeft: {
            xs: 0,
            md: '2rem',
          },
          paddingBottom: {
            xs: 0,
            md: '2rem',
          },
        }}
      >
        <Title title="successful.title" align="left" />
      </Grid>
      <Grid item xs={12} md={2}></Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          marginTop: '3rem',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: 'white',
            fontWeight: 400,
            textAlign: 'left',
            lineHeight: '155%',
            fontSize: '1rem',

            '& sup': {
              fontSize: '.6em',
              lineHeight: '.5',
            },
          }}
          component="div"
        >
          <div
            dangerouslySetInnerHTML={{ __html: t('successful.description') }}
            style={{
              fontFamily: 'Arial',
            }}
          ></div>
        </Typography>
      </Grid>
      <Grid item xs={12} md={1}></Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          marginTop: '3rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: 'white',
            fontWeight: 400,
            textAlign: 'left',
            lineHeight: '155%',
            fontSize: '1rem',

            '& sup': {
              fontSize: '.6em',
              lineHeight: '.5',
            },
          }}
          component="div"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t('successful.description2'),
            }}
            style={{
              fontFamily: 'Arial',
            }}
          ></div>
        </Typography>
      </Grid>
      <Grid item xs={12} md={1}></Grid>

      <Grid item xs={12} md={3}></Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column',
          marginTop: '4rem',
        }}
      >
        <img
          src={successImgs[i18n.language as 'en' | 'fr' | 'de']}
          alt="success"
          style={{
            width: '100%',
          }}
        />
        <CustomButton
          sx={{
            marginTop: '3rem',
          }}
          title={t('successful.button')}
          onClick={openContact}
        />
      </Grid>
      <Grid item xs={12} md={2}></Grid>
    </Grid>
  );
};

export default Successful;

