import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../common/CustomButton';
import { Brand } from '../Rank/Rank.type';

interface DiscoverRankProps {
  brand: Brand | null;
  openContact: () => void;
}

const Line = ({
  isBrand,
  isHeader,
  rank_2023,
  aggr_2023,
  dynamism_diff_2023,
  trust_diff_2023,
}: {
  isBrand: boolean;
  isHeader?: boolean;
  rank_2023: number | string;
  aggr_2023: number | string;
  dynamism_diff_2023: number | string;
  trust_diff_2023: number | string;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: isHeader ? '.5rem' : isBrand ? '1.75rem' : 0,
        margin: isHeader ? '4rem 0 2rem 0' : isBrand ? '0 0 4rem auto' : 0,
        borderBottom: isHeader ? '1px solid white' : 'none',
        width: '100%',
        transition: 'all .5s ease-in-out',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: isHeader
            ? {
                xs: '.55rem',
                sm: '.7rem',
              }
            : { xs: '2rem', sm: '3.25rem' },
          fontWeight: 500,
          textTransform: 'uppercase',
          color: 'white',
          width: '25%',
          textAlign: 'left',
          opacity: isBrand ? 1 : 0,
          transition: 'all .5s ease-in-out',
          paddingRight: '.5rem',
        }}
      >
        {rank_2023}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: isHeader
            ? {
                xs: '.55rem',
                sm: '.7rem',
              }
            : { xs: '.9rem', sm: '1rem' },
          fontWeight: 500,
          textTransform: 'uppercase',
          color: 'white',
          width: '25%',
          textAlign: 'left',
          opacity: isBrand ? 1 : 0,
          transition: 'all .5s ease-in-out',
          paddingRight: '.5rem',
        }}
      >
        {aggr_2023}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: isHeader
            ? {
                xs: '.55rem',
                sm: '.7rem',
              }
            : { xs: '.9rem', sm: '1rem' },
          fontWeight: 500,
          textTransform: 'uppercase',
          color: 'white',
          width: '25%',
          textAlign: 'right',
          opacity: isBrand ? 1 : 0,
          transition: 'all .5s ease-in-out',
          paddingRight: '.5rem',
        }}
      >
        {dynamism_diff_2023}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: isHeader
            ? {
                xs: '.55rem',
                sm: '.7rem',
              }
            : { xs: '.9rem', sm: '1rem' },
          fontWeight: 500,
          textTransform: 'uppercase',
          color: 'white',
          width: '25%',
          textAlign: 'right',
          opacity: isBrand ? 1 : 0,
          transition: 'all .5s ease-in-out',
        }}
      >
        {trust_diff_2023}
      </Typography>
    </Box>
  );
};

const DiscoverRank: React.FC<DiscoverRankProps> = ({ brand, openContact }) => {
  const [lastBrand, setLastBrand] = React.useState<Brand | null>(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (brand) {
      setLastBrand(brand);
    }
  }, [brand]);

  return (
    <Grid
      sx={{
        maxHeight: brand ? '500px' : '0px',
        overflow: 'hidden',
        transition: 'all .5s ease-in-out',
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Line
        isBrand={true}
        isHeader={true}
        rank_2023={t('rank.rank2023')}
        aggr_2023={t('rank.aggr2023')}
        dynamism_diff_2023={t('rank.dynamism_yoy')}
        trust_diff_2023={t('rank.trust_yoy')}
      />
      <Line
        isBrand={true}
        rank_2023={brand?.rank_2023 || lastBrand?.rank_2023 || 'XX'}
        aggr_2023={brand?.aggr_2023 || lastBrand?.aggr_2023 || 'Brand'}
        dynamism_diff_2023={
          brand?.dynamism_diff_2023 || lastBrand?.dynamism_diff_2023 || 'X,X'
        }
        trust_diff_2023={
          brand?.trust_diff_2023 || lastBrand?.trust_diff_2023 || 'X,X'
        }
      />

      <CustomButton
        title={t('discover.button')}
        sx={{
          opacity: brand ? 1 : 0,
          maxHeight: brand ? '100px' : 0,
          overflow: 'hidden',
          transition: 'all .5s ease-in-out',
        }}
        onClick={openContact}
      />
    </Grid>
  );
};

export default DiscoverRank;

