import React, { useRef } from 'react';
import { Box, ListItemText, MenuItem, Menu, Typography } from '@mui/material';
import useLocales from '../../locales/useLocales';
import DownArrow from '../../assets/down_arrow.svg';
import DownArrowBlack from '../../assets/down_arrow_black.svg';

interface LanguageChangeProps {
  dark?: boolean;
}

const LanguageChange: React.FC<LanguageChangeProps> = ({ dark }) => {
  const [open, setOpen] = React.useState(false);
  const { currentLang, onChangeLang, allLang } = useLocales();
  const anchorRef = useRef(null);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: dark ? 'black' : 'white',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: '.9rem',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          textTransform: 'uppercase',
          fontFamily: 'Arial',
          fontWeight: 700,
          letterSpacing: '0.6px',
        }}
        ref={anchorRef}
        onClick={() => setOpen(true)}
      >
        {currentLang.value}
        <img
          src={dark ? DownArrowBlack : DownArrow}
          alt="down arrow"
          style={{ marginLeft: '.5rem' }}
        />
      </Typography>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: '#920902',
            color: 'white',

            '& .MuiList-root': {
              padding: 0,

              '& .MuiMenuItem-root': {
                border: 'none',
                backgroundColor: '#920902',
                transition: 'all 0.2s ease',

                '&:hover': {
                  backgroundColor: '#720600',
                },

                '&.Mui-selected': {
                  backgroundColor: '#720600',
                  cursor: 'default',
                },
              },
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                setOpen(false);
              }}
              sx={{
                py: 1,
                px: 2.5,
                backgroundColor: '#920902',
                color: 'white',
              }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

export default LanguageChange;

